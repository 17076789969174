import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../msalConfig';
import { Link } from 'react-router-dom';
import {  useFeatureFlag } from '../FeatureFlagProvider'; // Adjust the import path as necessary

export default function NavigationBar() {
  const { instance } = useMsal();

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          {/* Site name on the left */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Voice QC
          </Typography>

          {/* Conditional rendering of Login/Logout buttons */}
          <UnauthenticatedTemplate>
            <Button color="inherit" onClick={handleLoginRedirect}>
              Login
            </Button>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
         
            <Button color="inherit" component={Link} to="/home">
              Home
            </Button>
            <Button color="inherit" component={Link} to="/logs">
              Logs
            </Button>
            <Button color="inherit" component={Link} to="/usage">
              Usage
            </Button>
            {useFeatureFlag('projectsFeature') && (
              <Button color="inherit" component={Link} to="/projects">
                Projects
              </Button>
            )}
            <Button color="inherit" onClick={handleLogoutRedirect}>
              Logout
            </Button>
          
          </AuthenticatedTemplate>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
