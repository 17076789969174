import axios, { AxiosError } from "axios";
import { OpenAiSTTResult } from "./Types";


const { REACT_APP_OPEN_AI_API_KEY } = process.env;


const serviceOpenAi = axios.create({
    baseURL: 'https://api.openai.com/v1/audio/transcriptions',
    headers: {
      'Authorization': `Bearer ${REACT_APP_OPEN_AI_API_KEY}`
    }
  });
  

const createFormData = (file: File, model: string) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('model', model);
    return formData;
}

const postFormData = async (formData: FormData, retryCount:number): Promise<any> => {
    try {
        const response = await serviceOpenAi.post('', formData, {
            headers: { 'content-type': 'application/x-www-form-urlencoded;charset=utf-8' }
        });
        return response.data ;
    } catch (error) {
        const axiosError = error as AxiosError;
        console.log('error: ', axiosError);
        if (axiosError.response && axiosError.response.status === 429) {
            let jiter = Math.floor(Math.random() * 200);
            let backoff = Math.pow(2, retryCount);
            let delay = 1000 * backoff + jiter;
            delay = Math.min(delay, 30000);
            console.log('retrying after: ', delay);
            await new Promise(resolve => setTimeout(resolve, delay));
            return postFormData(formData, retryCount ++);
        } else {
            throw error;
        }
    }
}

// Transcribe the file using the OpenAI STT API
// always reaturns a result, even if there is an error
export async function transcribe(f: File) {
    let fileName = f.name;  // store the file name for exception handling
    try {
        console.log('transcribing: ', fileName);
        let formData = createFormData(f, 'whisper-1');
        let transcriptionText = await postFormData(formData,1) as OpenAiSTTResult;
        let result = { fileName: fileName, text: transcriptionText.text };
        console.log('transcribed: ', result);
        return result;
    } catch (error) {
        // capture the error and return a result with the error
        // check console to see the error
        console.error('transcribing error: ', error);
        let result = { fileName: fileName, text: 'error' };
        return result;
    }
}