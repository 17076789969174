import React, { useEffect, useRef, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FileItem, TranscriptFileResult } from '../Types';
import { concatMap, from } from 'rxjs';
import { transcribe } from '../SpeechToText';
import { createFileItem } from '../Utils';
import { mergeMap } from 'rxjs/operators';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from '../msalConfig';
import { createSession, updateSession, updateSessionName } from '../SvcService';
import DragAndDropZone from '../Components/DragAndDropZone';
import ExportButton from '../Components/ExportButton';
import SessionNameComponent from '../Components/SessionNameComponent';
import FileProgressComponent from '../Components/FileProgressComponent';
import FilesTableComponent from '../Components/FilesTableComponent';
import SessionGroupComponent from '../Components/SessionGroupComponent'; // import the new component

function Home() {
    const theme = useTheme();
    const [files, setFiles] = useState<FileItem[]>([]);
    const [overallProgress, setOverallProgress] = useState(0);
    const [totalFiles, setTotalFiles] = useState(0);
    const [processedFiles, setProcessedFiles] = useState(0);
    const [sortField, setSortField] = useState(""); // The field to sort by
    const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
    const [sessionName, setSessionName] = useState('');
    const [sessionId, setSessionId] = useState<string | null>(null);
    const [isEditingSessionName, setIsEditingSessionName] = useState(false);
    const msalInstanceRef = useRef<PublicClientApplication | null>(null);

    useEffect(() => {
        const initializeMsal = async () => {
            msalInstanceRef.current = new PublicClientApplication(msalConfig);
            await msalInstanceRef.current.initialize();
            const account = msalInstanceRef.current.getAllAccounts()[0];
            const tokenRequest = {
                account,
                scopes: loginRequest.scopes // Replace with your API scopes
            }
            try {
                await msalInstanceRef.current.acquireTokenSilent(tokenRequest);
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    if (msalInstanceRef.current) {
                        msalInstanceRef.current.acquireTokenRedirect(tokenRequest);
                    }
                }
            }
        };
        initializeMsal();
    }, []);

    const sortFiles = (files: any[]) => {
        return files.sort((a, b) => {
            if (sortField && a[sortField] < b[sortField]) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (sortField && a[sortField] > b[sortField]) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    const processFiles = async (files: FileList) => {
        const newSessionId = await createSession();
        setSessionId(newSessionId);
        setIsEditingSessionName(true);

        const totalFilesCount = files.length;
        setTotalFiles(files.length);
        let processedFilesCount = 0;
        setProcessedFiles(0);
        from(files).pipe(
            concatMap(file =>
                from(transcribe(file)).pipe(
                    mergeMap(async result => {
                        updateFilesState(result);
                        setProcessedFiles(f => f + 1);
                        processedFilesCount++;
                        setOverallProgress((processedFilesCount / totalFilesCount) * 100);
                        await updateSession(newSessionId, file, result);
                        return result; // return the result for the next operator in the pipe
                    })
                )
            )
        ).subscribe({
            complete: () => console.log('All files processed')
        });
    };

    const updateFilesState = (result: TranscriptFileResult) => {
        setFiles((currentFiles: FileItem[]) => {
            let newCurrentFiles = new Array<FileItem>();
            currentFiles.forEach(element => {
                if (element.name === result.fileName) {
                    element.text = result.text;
                    element.status = 'done';
                    newCurrentFiles.push(element);
                } else {
                    newCurrentFiles.push(element);
                }
            });
            return newCurrentFiles;
        });
    };

    const handleDragOver = async (event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Stops some browsers from redirecting.
        event.preventDefault(); // Stops some browsers from redirecting.
        event.dataTransfer.dropEffect = 'copy'; // This is the default action, if the drop event is not handled.
    }

    const handleOnDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Stops some browsers from redirecting.
        event.preventDefault(); // Stops some browsers from redirecting.

        const files = event.dataTransfer.items
            ? Array.from(event.dataTransfer.items)
                .filter(item => item.kind === 'file')
                .filter(item => item.type === 'audio/wav')
                .map(item => item.getAsFile())
                .filter(file => file !== null) as File[]
            : Array.from(event.dataTransfer.files)
                .filter(file => file.type === 'audio/wav') as File[];

        const fileItems = files.map(createFileItem);
        event.dataTransfer.clearData();
        setFiles(fileItems);
        processFiles(event.dataTransfer.files);
    }

    const handleChooseFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const fileItems = Array.from(files).map(createFileItem);
            setFiles(fileItems);
            processFiles(files);
        }
    }

    const handlePlayAudio = (file: File) => {
        const audioUrl = URL.createObjectURL(file);
        const audio = new Audio(audioUrl);
        audio.play();
    };

    const handleSort = (field: string) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc'); // Default to ascending when changing sort field
        }
    };

    const handleSaveSessionName = async () => {
        if (sessionId) {
            await updateSessionName(sessionId, sessionName);
            setIsEditingSessionName(false);
        }
    };

    const handleEditSessionName = () => {
        setIsEditingSessionName(true);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2, width: '90%', maxWidth: 'none' }}>
            <Paper elevation={1} sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{
                    width: '90%', display: 'flex', justifyContent: 'center', gap: 20, flexWrap: 'wrap',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column',
                        alignItems: 'center',
                    },
                }}>
                     <DragAndDropZone
                        handleOnDrop={handleOnDrop}
                        handleDragOver={handleDragOver}
                        handleChooseFiles={handleChooseFiles}
                    />
                    <ExportButton files={files} />
                </Box>

                {sessionId && (
                    <SessionNameComponent
                        isEditing={isEditingSessionName}
                        sessionName={sessionName}
                        setSessionName={setSessionName}
                        handleSave={handleSaveSessionName}
                        handleEdit={handleEditSessionName}
                    />
                )}

                {sessionId && (
                    <SessionGroupComponent
                        sessionId={sessionId}
                    />
                )}

                <FileProgressComponent
                    processedFiles={processedFiles}
                    totalFiles={totalFiles}
                    overallProgress={overallProgress}
                />

                <FilesTableComponent
                    files={files}
                    sortFiles={sortFiles}
                    handleSort={handleSort}
                    handlePlayAudio={handlePlayAudio}
                />
            </Paper>
        </Box>
    );
}

export default Home;
