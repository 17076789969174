import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ProjectAlignmentService, TranscriptDto, TranscriptMessage, UpdateTranscriptRequst } from '../../ProjectAlignmentService';

interface TranscriptReviewDialogProps {
  projectId: string;
  memberId: string;
  open: boolean;
  onClose: () => void;
}

const projectService = new ProjectAlignmentService();

const TranscriptReviewDialog: React.FC<TranscriptReviewDialogProps> = ({ projectId, memberId, open, onClose }) => {
  const [transcripts, setTranscripts] = useState<TranscriptDto[]>([]);
  const [editingMessage, setEditingMessage] = useState<TranscriptMessage | null>(null);
  const [editedText, setEditedText] = useState<string>('');

  useEffect(() => {
    const fetchTranscripts = async () => {
      try {
        const data = await projectService.getTranscripts(projectId, memberId);
        setTranscripts(data);
      } catch (error) {
        console.error('Error fetching transcripts', error);
      }
    };

    if (open) {
      fetchTranscripts();
    }
  }, [projectId, memberId, open]);

  const handleEditClick = (message: TranscriptMessage) => {
    setEditingMessage(message);
    setEditedText(message.text);
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedText(e.target.value);
  };

  const handleSaveEdit = async () => {
    if (editingMessage) {
      const updateRequest: UpdateTranscriptRequst = {
        transcriptId: editingMessage.transcriptMessageId,
        message: editedText,
      };
      await projectService.updateTranscript(projectId, memberId, editingMessage.transcriptMessageId, updateRequest);
      setEditingMessage(null);
      const data = await projectService.getTranscripts(projectId, memberId);
      setTranscripts(data);
    }
  };

  const handleSendClick = async (transcriptId: string) => {
    await projectService.sendTranscript(projectId, memberId, transcriptId);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Transcript Review</DialogTitle>
      <DialogContent>
        {transcripts.map((transcript) => (
          <Box key={transcript.transcriptId} sx={{ mb: 2 }}>
            <Typography variant='h6'>{`Transcript ID: ${transcript.transcriptId}`}</Typography>
            {transcript.messages.map((message) => (
              <Box key={message.transcriptMessageId} sx={{ mb: 1 }}>
                <Typography variant='body1'>{`Role: ${message.role}`}</Typography>
                <Typography variant='body2'>{message.text}</Typography>
                <Button variant="contained" color="primary" onClick={() => handleEditClick(message)}>
                  Edit
                </Button>
              </Box>
            ))}
            <Button variant="contained" color="primary" onClick={() => handleSendClick(transcript.transcriptId)}>
              Send Transcript
            </Button>
          </Box>
        ))}
        {editingMessage && (
          <Box sx={{ mt: 2 }}>
            <Typography variant='h6'>Edit Message</Typography>
            <TextField
              label="Message"
              value={editedText}
              onChange={handleEditChange}
              fullWidth
              multiline
              rows={4}
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleSaveEdit}>
              Save
            </Button>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TranscriptReviewDialog;
