import React from 'react';
import { Paper, Stack, Typography, LinearProgress } from '@mui/material';

function FileProgressComponent({ processedFiles, totalFiles, overallProgress }:
    { processedFiles: number, 
      totalFiles: number, 
      overallProgress: number }
) {
    return (
        <Paper elevation={6} sx={{ width: '90%', alignItems: 'center', gap: 1, mt: 2 }}>
            <Stack sx={{ width: '100%', alignItems: 'center', gap: 3 }}>
                <Typography sx={{ color: 'white' }}>Progress: {processedFiles}/{totalFiles}</Typography>
                <LinearProgress variant="determinate" value={overallProgress} sx={{ width: '100%' }} />
            </Stack>
        </Paper>
    );
}

export default FileProgressComponent;
