import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SessionDetailView, getSessionDetails, updateSessionName } from '../SvcService';
import { Box, Button, Card, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { ExportSessionItemViewToCsv } from '../ExportToCsv';
import SessionNameComponent from '../Components/SessionNameComponent';
import SessionGroupComponent from '../Components/SessionGroupComponent';

type LogProps = {};

const Log: React.FC<LogProps> = () => {
    const { sessionId: rawSessionId } = useParams<{ sessionId: string }>();
    const sessionId = rawSessionId ?? null;
    const [session, setSession] = useState<SessionDetailView | null>(null);
    const [sessionName, setSessionName] = useState<string>('');
    const [isEditingSessionName, setIsEditingSessionName] = useState(false);
    useEffect(() => {
        if (sessionId) {
            getSessionDetails(sessionId).then(data => {
                setSession(data);
                setSessionName(data.name);
            });
        }
    }, [sessionId]);

    const handleSaveSessionName = async () => {
        if (sessionId) {
            await updateSessionName(sessionId, sessionName);
            setIsEditingSessionName(false);
        }
    };
    const handleEditSessionName = () => {
        setIsEditingSessionName(true);
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2, width: '90%', maxWidth: 'none' }}>
            <Paper elevation={1} sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Paper elevation={1} sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Paper elevation={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box>
                            <Stack direction='row' spacing={4}>
                                <Card>
                                    <Stack direction='column' spacing={4} alignItems='center'>
                                        <Typography>Item Count</Typography>
                                        <Typography>{session?.itemCount}</Typography>
                                    </Stack>
                                </Card>
                                <Card>
                                    <Stack direction='column' spacing={4} alignItems='center'>
                                        <Typography>Character Count</Typography>
                                        <Typography>{session?.characterCount}</Typography>
                                    </Stack>
                                </Card>
                                <Card>
                                    <Stack direction='column' spacing={4} alignItems='center'>
                                        <Typography>Created At</Typography>
                                        <Typography>{session?.createAtUtc?.toLocaleString()}</Typography>
                                    </Stack>
                                </Card>
                            </Stack>
                        </Box>
                    </Paper>
                    <Box sx={{ width: '90%', display: 'flex', justifyContent: 'center', gap: 20, flexWrap: 'wrap' }}>
                        <Paper elevation={6} sx={{ width: 200, height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px dashed grey' }}>
                            <Button
                                variant="contained"
                                disabled={session === null}
                                onClick={() => ExportSessionItemViewToCsv(session?.items)}
                                startIcon={<CloudUploadIcon />}
                            >
                                Export
                            </Button>
                        </Paper>
                    </Box>
                </Paper>

                <SessionNameComponent
                    isEditing={isEditingSessionName}
                    sessionName={sessionName}
                    setSessionName={setSessionName}
                    handleSave={handleSaveSessionName}
                    handleEdit={handleEditSessionName}
                />
                <SessionGroupComponent
                    sessionId={sessionId}
                />
                <Paper elevation={6} sx={{ width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, mt: 2, p: 2 }}>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>File Name</TableCell>
                                <TableCell>Text</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {session && session.items.map((item) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.fileName}</TableCell>
                                    <TableCell>{item.transcription}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Paper>
        </Box>
    );
};

export default Log;
