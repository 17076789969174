import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import MemberDialog from '../Components/ProjectAlignment/MemberDialog';
import MemberTable from '../Components/ProjectAlignment/MemberTable';
import ProjectDetails from '../Components/ProjectAlignment/ProjectDetails';
import ProjectTable from '../Components/ProjectAlignment/ProjectTable';
import TranscriptReviewDialog from '../Components/ProjectAlignment/TranscriptReviewDialog';
import { ProjectAlignmentService, ProjectInfo, ProjectDto, MemberInfo, UpdateMemberRequest, UpdateProjectRequest, CreateProjectRequest, AddMemberRequest } from '../ProjectAlignmentService';


const projectService = new ProjectAlignmentService();

const ProjectList: React.FC = () => {
  const [projects, setProjects] = useState<ProjectInfo[]>([]);
  const [selectedProject, setSelectedProject] = useState<ProjectDto | null>(null);
  const [editProject, setEditProject] = useState<UpdateProjectRequest>({ name: '', description: '', messageTemplate: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [members, setMembers] = useState<MemberInfo[]>([]);
  const [selectedMember, setSelectedMember] = useState<MemberInfo | null>(null);
  const [isReviewingTranscript, setIsReviewingTranscript] = useState(false);
  const [editMember, setEditMember] = useState<UpdateMemberRequest>({ name: '', contactType: '', contactValue: '', specialInstructions: '' });
  const [isEditingMember, setIsEditingMember] = useState(false);
  const [isAddingMember, setIsAddingMember] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projects = await projectService.getProjects();
        setProjects(projects);
      } catch (error) {
        console.error('Error fetching projects', error);
      }
    };
    fetchProjects();
  }, []);

  const handleRowClick = async (projectId: string | null) => {
    if (projectId) {
      try {
        const project = await projectService.getProject(projectId);
        setSelectedProject(project);
        setEditProject({ name: project.name, description: project.description, messageTemplate: project.messageTemplate });
        setIsEditing(false);
        setIsAdding(false);

        const projectMembers = await projectService.getProjectMembers(projectId);
        setMembers(projectMembers);
      } catch (error) {
        console.error('Error fetching project details', error);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditProject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveChanges = async () => {
    try {
      if (isAdding) {
        const newProject: CreateProjectRequest = { name: editProject.name, description: editProject.description, messageTemplate: editProject.messageTemplate };
        await projectService.createProject(newProject);
        const projects = await projectService.getProjects();
        setProjects(projects);
        setIsAdding(false);
      } else if (selectedProject && selectedProject.projectId) {
        await projectService.updateProject(selectedProject.projectId, editProject);
        setIsEditing(false);
        const projects = await projectService.getProjects();
        setProjects(projects);
      }
      setSelectedProject(null);
    } catch (error) {
      console.error('Error updating or adding project', error);
    }
  };

  const handleDeleteProject = async () => {
    if (selectedProject && selectedProject.projectId) {
      try {
        await projectService.deleteProject(selectedProject.projectId);
        const projects = await projectService.getProjects();
        setProjects(projects);
        setSelectedProject(null);
        setIsEditing(false);
        setIsAdding(false);
      } catch (error) {
        console.error('Error deleting project', error);
      }
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    if (selectedProject) {
      setEditProject({ name: selectedProject.name, description: selectedProject.description, messageTemplate: selectedProject.messageTemplate });
    }
    setIsEditing(false);
    setIsAdding(false);
  };

  const handleNewProjectClick = () => {
    setSelectedProject(null);
    setEditProject({ name: '', description: '', messageTemplate: '' });
    setIsEditing(true);
    setIsAdding(true);
  };

  const handleMemberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditMember((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSaveMemberChanges = async () => {
    try {
      if (isAddingMember && selectedProject && selectedProject.projectId) {
        const newMember: AddMemberRequest = { name: editMember.name, contactType: editMember.contactType, contactValue: editMember.contactValue, specialInstructions: editMember.specialInstructions };
        await projectService.addProjectMembers(selectedProject.projectId, [newMember]);
        const projectMembers = await projectService.getProjectMembers(selectedProject.projectId);
        setMembers(projectMembers);
        setIsAddingMember(false);
      } else if (selectedMember && selectedProject && selectedProject.projectId) {
        await projectService.updateMember(selectedProject.projectId, selectedMember.id, editMember);
        const projectMembers = await projectService.getProjectMembers(selectedProject.projectId);
        setMembers(projectMembers);
        setIsEditingMember(false);
        setSelectedMember(null);
      }
    } catch (error) {
      console.error('Error updating or adding member', error);
    }
  };

  const handleDeleteMember = async () => {
    if (selectedMember && selectedProject && selectedProject.projectId) {
      try {
        await projectService.deleteMember(selectedProject.projectId, selectedMember.id);
        const projectMembers = await projectService.getProjectMembers(selectedProject.projectId);
        setMembers(projectMembers);
        setSelectedMember(null);
        setIsEditingMember(false);
        setIsAddingMember(false);
      } catch (error) {
        console.error('Error deleting member', error);
      }
    }
  };

  const handleEditMemberClick = (member: MemberInfo) => {
    setSelectedMember(member);
    setEditMember({ name: member.name, contactType: member.contacts[0].contactType, contactValue: member.contacts[0].contactValue, specialInstructions: member.specialInstructions });
    setIsEditingMember(true);
  };

  const handleReviewClick = (member: MemberInfo) => {
    setSelectedMember(member);
    setIsReviewingTranscript(true);
  };

  const handleNewMemberClick = () => {
    setSelectedMember(null);
    setEditMember({ name: '', contactType: '', contactValue: '', specialInstructions: '' });
    setIsAddingMember(true);
    setIsEditingMember(true);
  };

  const handleCancelMemberClick = () => {
    setIsEditingMember(false);
    setIsAddingMember(false);
    setSelectedMember(null);
  };

  const handleCloseReviewDialog = () => {
    setIsReviewingTranscript(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2, width: '90%', maxWidth: 'none' }}>
      <Paper elevation={1} sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant='h4' gutterBottom>
          Project List
        </Typography>
        <Button variant="contained" color="primary" onClick={handleNewProjectClick} sx={{ mb: 2 }}>
          New Project
        </Button>
        <ProjectTable projects={projects} onRowClick={handleRowClick} />
        {(selectedProject || isAdding) && (
          <ProjectDetails
            editProject={editProject}
            isEditing={isEditing}
            isAdding={isAdding}
            selectedProject={selectedProject}
            onInputChange={handleInputChange}
            onSaveChanges={handleSaveChanges}
            onEditClick={handleEditClick}
            onDeleteProject={handleDeleteProject}
            onCancelClick={handleCancelClick}
          />
        )}
        {selectedProject && !isReviewingTranscript && (
          <MemberTable
            members={members}
            onEditMemberClick={handleEditMemberClick}
            onReviewClick={handleReviewClick}
            onNewMemberClick={handleNewMemberClick}
          />
        )}
        {(isEditingMember || isAddingMember) && (
          <MemberDialog
            isOpen={isEditingMember || isAddingMember}
            isAddingMember={isAddingMember}
            editMember={editMember}
            onClose={handleCancelMemberClick}
            onInputChange={handleMemberInputChange}
            onSaveMemberChanges={handleSaveMemberChanges}
            onDeleteMember={handleDeleteMember}
          />
        )}
        {isReviewingTranscript && selectedMember && selectedProject && (
          <TranscriptReviewDialog
            projectId={selectedProject.projectId}
            memberId={selectedMember.id}
            open={isReviewingTranscript}
            onClose={handleCloseReviewDialog}
          />
        )}
      </Paper>
    </Box>
  );
};

export default ProjectList;
