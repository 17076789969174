import React, { useEffect, useState } from 'react';
import { UsageEOMView, getUsageByEOM } from '../SvcService';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

interface UsageProps {
}

const UsagePage: React.FC<UsageProps> = () => {
    const [usageData, setUsageData] = useState<UsageEOMView[]>([]);

    useEffect(() => {
        const fetchUsageData = async () => {
            const data = await getUsageByEOM();
            setUsageData(data);
        };

        fetchUsageData();
    }, []); // This ensures fetchUsageData is called only once on component mount

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2, width: '90%', maxWidth: 'none' }}>
        <Paper elevation={1} sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='h4' gutterBottom>
                Usage
            </Typography>
            <TableContainer component={Paper} >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                          
                            <TableCell align="center">Month</TableCell>
                            <TableCell align="center">Character Count</TableCell>
                          
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usageData.map((usage, index) => (
                            <TableRow key={usage.id}>
                                <TableCell align="center">{new Date(usage.eom).toLocaleDateString()}</TableCell>
                                <TableCell align="center">{usage.characterCount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    </Box>
    );
};
export default UsagePage;