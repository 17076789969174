import { blue, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';


// https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212', 
      paper: '#121212', 
    },
    primary: {
      light: red[300],
      main: red[500],
      dark: red[700],
      contrastText: '#fff',
    },
    secondary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
      contrastText: '#000',
    },
    error: {
      main: '#CF6679'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: '#121212',
        },
      },
    },
  },
});

export default theme;
