import { SessionItemView } from "./SvcService";
import { FileItem } from "./Types";

interface FileData {
    name: string;
    text: string;
}

// convert the files array to a csv string
// each field is wrapped in double quotes as may contain commas or other special characters.
const createCsvContent = (files: FileData[]) => {
    let headers = ['File Name', 'Text'];
    let csvArray = [headers, ...files.map(file => [file.name, file.text])];
    return csvArray.map(e => e.map(item => `"${item}"`).join(",")).join("\n");
}

const downloadFile = (content: string, fileName: string) => {
    let blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const fileItemToFileData = (item: FileItem): FileData => {
    return {
        name: item.name,
        text: item.text
    }
}

const itemToFileFunc = (item: SessionItemView): FileData => {
    return {
        name: item.fileName,
        text: item.transcription
    }
}

export const ExportFileItemsToCsv = (data: FileItem[]) => {
    console.log('exporting');
    let fileData = data.map(fileItemToFileData);
    let csvContent = createCsvContent(fileData);
    downloadFile(csvContent, "transcriptions.csv");
}

export const ExportSessionItemViewToCsv = (data: SessionItemView[] | undefined) => {
    if (!data) {
        console.log('No data to export');
        return;
    }
    console.log('exporting');
    let files = data.map(itemToFileFunc);
    let csvContent = createCsvContent(files);
    downloadFile(csvContent, "transcriptions.csv");
}