import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, IconButton, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SessionView, getSessions, updateSessionName } from '../SvcService';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

type LogsProps = {};

const Logs: React.FC<LogsProps> = () => {
    const [sessions, setSessions] = useState<SessionView[]>([]);
    const [editingSessionId, setEditingSessionId] = useState<string | null>(null);
    const [editedName, setEditedName] = useState<string>('');
    const [groupFilter, setGroupFilter] = useState<string>('All');
    const [groupNames, setGroupNames] = useState<string[]>([]);

    useEffect(() => {
        const fetchSessions = async () => {
            const sessions = await getSessions();
            setSessions(sessions);
            setGroupNames(['All', ...Array.from(new Set(sessions.map(session => session.groupName)))]);
        };

        fetchSessions();
    }, []);

    const handleEditClick = (sessionId: string, currentName: string) => {
        setEditingSessionId(sessionId);
        setEditedName(currentName);
    };

    const handleSaveClick = async (sessionId: string) => {
        await updateSessionName(sessionId, editedName);  // Ensure you have an updateSessionName function in your service
        setSessions((prevSessions) =>
            prevSessions.map((session) =>
                session.sessionId === sessionId ? { ...session, name: editedName } : session
            )
        );
        setEditingSessionId(null);
    };

    const handleCancelClick = () => {
        setEditingSessionId(null);
        setEditedName('');
    };

    // const handleGroupFilterChange = (event: React.ChangeEvent<{ value: any }>) => {
    //     setGroupFilter(event.target.value as string);
    // };
    const handleGroupFilterChange = (event: SelectChangeEvent<string>) => {
        setGroupFilter(event.target.value);
    };
    const filteredSessions = groupFilter === 'All' ? sessions : sessions.filter(session => session.groupName === groupFilter);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 2, width: '90%', maxWidth: 'none' }}>
            <Paper elevation={1} sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Paper elevation={6} sx={{ width: '90%', alignItems: 'center', gap: 1, mt: 2, p: 2, display: 'flex', flexDirection: 'column' }} >
                    <FormControl sx={{ mb: 2, minWidth: 200 }}>
                        <InputLabel id="group-filter-label">Group Name</InputLabel>
                        <Select
                            labelId="group-filter-label"
                            id="group-filter"
                            value={groupFilter}
                            onChange={handleGroupFilterChange} >
                            {groupNames.map((groupName, index) => (
                                <MenuItem key={index} value={groupName}>
                                    {groupName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Paper>
                <Paper elevation={6} sx={{ width: '90%', alignItems: 'center', gap: 1, mt: 2, p: 2, display: 'flex', flexDirection: 'column' }} >
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Session Id</TableCell>
                                    <TableCell align="center">Group Name</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Item Count</TableCell>
                                    <TableCell align="center">Character Count</TableCell>
                                    <TableCell align="center">Created At</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredSessions.map((session, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row" align="center">
                                            <Link to={`/log/${session.sessionId}`}>
                                                {session.sessionId}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center">{session.groupName}</TableCell>
                                        <TableCell align="center">
                                            {editingSessionId === session.sessionId ? (
                                                <TextField
                                                    value={editedName}
                                                    onChange={(e) => setEditedName(e.target.value)}
                                                />
                                            ) : (
                                                session.name
                                            )}
                                        </TableCell>
                                        <TableCell align="center">{session.itemCount}</TableCell>
                                        <TableCell align="center">{session.characterCount}</TableCell>
                                        <TableCell align="center">{session.createAtUtc.toLocaleString()}</TableCell>
                                        <TableCell align="center">
                                            {editingSessionId === session.sessionId ? (
                                                <>
                                                    <IconButton onClick={() => handleSaveClick(session.sessionId)}><CheckIcon /></IconButton>
                                                    <IconButton onClick={handleCancelClick}><CloseIcon /></IconButton>
                                                </>
                                            ) : (
                                                <IconButton onClick={() => handleEditClick(session.sessionId, session.name)}><EditIcon /></IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Paper>
        </Box>
    );
}

export default Logs;
