import React from 'react';
import { Paper, TextField, Typography, Button, Box } from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';

function SessionNameComponent({ isEditing, sessionName, setSessionName, handleSave, handleEdit }:
    { isEditing: boolean, 
      sessionName: string, 
      setSessionName: (sessionName: string) => void, 
      handleSave: () => void, 
      handleEdit: () => void }
) {
    return (
        <Paper
            elevation={6}
            sx={{ width: '90%', alignItems: 'center', gap: 1, mt: 2, p: 2, display: 'flex', justifyContent: 'space-between' }}
        >
            {isEditing ? (
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <Typography variant="h6" sx={{ mr: 1, whiteSpace: 'nowrap' }}>
                        Session Name
                    </Typography>
                    <TextField
                        label="Session Name"
                        value={sessionName}
                        onChange={(e) => setSessionName(e.target.value)}
                        fullWidth
                        sx={{ marginRight: 1 }}
                    />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <Typography variant="h6" sx={{ mr: 2 }}>
                        Session Name
                    </Typography>
                    <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                        {sessionName}
                    </Typography>
                </Box>
            )}
            {isEditing ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    startIcon={<SaveIcon />}
                >
                    Save
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEdit}
                    startIcon={<EditIcon />}
                >
                    Edit
                </Button>
            )}
        </Paper>
    );
}

export default SessionNameComponent;
