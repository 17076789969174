import React, { useState, useEffect, useMemo } from 'react';
import { UserService } from '../UserService';
import { Button, TextField, Paper, Box, Autocomplete, Typography } from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { updateSessionGroupName} from '../SvcService'; // Import the new service method
interface SessionGroupComponentProps {
    sessionId: string | null ;
}

const SessionGroupComponent: React.FC<SessionGroupComponentProps> = ({ sessionId }) => {
    const [groups, setGroups] = useState<string[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(true);
    const userService = useMemo(() => new UserService(), []);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const sessionGroupInfo = await userService.getSessionGroups();
                setGroups(sessionGroupInfo.sessionGroups.map(group => group.groupName));
            } catch (error) {
                console.error('Error fetching groups', error);
            }
        };

        fetchGroups();
    }, [userService]);

    const handleAssignGroup = async () => {
        console.log('Assigning group to session');
        const groupToAssign = inputValue || selectedGroup;
        console.log(`groupToAssign: ${groupToAssign}`);
        console.log(`inputValue: ${inputValue}`);
        if (sessionId && groupToAssign) {
            try {
                if (!groups.includes(groupToAssign)) {
                    // The selected group is new, add it first
                    console.log(`Saving new group: ${groupToAssign}`);
                    await userService.addSessionGroup(groupToAssign);
                    setGroups(prevGroups => [...prevGroups, groupToAssign]);
                    console.log(`Saved new group: ${groupToAssign}`);
                }

                // Assuming there is a service method to assign the group to a session
                //await userService.assignGroupToSession(sessionId, groupToAssign);
                await updateSessionGroupName(sessionId, groupToAssign);
                console.log(`Assigned group ${groupToAssign} to session ${sessionId}`);
                setSelectedGroup(groupToAssign);
                setInputValue('');
                setIsEditing(false);
            } catch (error) {
                console.error('Error assigning group', error);
            }
        } else {
            console.error('No group selected or typed');
        }
    };

    const handleGroupChange = (event: any, newValue: string | null) => {
        setSelectedGroup(newValue);
        if (newValue && !groups.includes(newValue)) {
            setInputValue(newValue);
        } else {
            setInputValue('');
        }
    };

    const handleInputChange = (event: any, newInputValue: string) => {
        setInputValue(newInputValue);
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    return (
        <Paper elevation={6} sx={{ width: '90%', alignItems: 'center', gap: 1, mt: 2, p: 2, display: 'flex', flexDirection: 'column' }} >
            {isEditing ? (
                <Box
                    sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                >
                    <Autocomplete
                        options={groups}
                        freeSolo
                        value={inputValue || selectedGroup}
                        onChange={handleGroupChange}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Session Group"
                                placeholder="Type to add a new group"
                                fullWidth
                                sx={{ marginRight: 1 }}
                            />
                        )}
                        sx={{ flexGrow: 1, marginRight: 2 }}
                    />
                    <Button variant="contained" color="primary" onClick={handleAssignGroup} startIcon={<SaveIcon />}>
                        Save
                    </Button>
                </Box>
            ) : (
                <Box
                    sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
                >
                    <Typography variant="h6" align="center" sx={{ flexGrow: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h6" sx={{ mr: 2 }}>
                                Group Name
                            </Typography>
                            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
                                {selectedGroup}
                            </Typography>
                        </Box>
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEdit}
                        startIcon={<EditIcon />}
                    >
                        Edit
                    </Button>
                </Box>
            )}
        </Paper>
    );
};

export default SessionGroupComponent;
