import React from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

function ProductDescription() {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Voice QC
      </Typography>
      <Typography paragraph>
        Voice QC offers an innovative solution for audio engineers, streamlining the tedious task of quality control. With our platform, effortlessly upload and analyze thousands of audio files, gaining instant insights through accurate transcriptions and intuitive playback features. Voice QC transforms hours of manual checking into a swift, precise process, ensuring your audio aligns perfectly with your expectations.
      </Typography>
      <Table>
        <TableBody>
        <TableCell component="th" scope="row"></TableCell>
        <TableRow>
            <TableCell component="th" scope="row" >
            Efficient Batch Transcription
            </TableCell>
            <TableCell>
            Instantly transcribe multiple audio files, easily verifying content against expectations.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" >
              Intuitive File Management
            </TableCell>
            <TableCell>
              Seamlessly navigate, play back, and review audio files and their corresponding transcriptions in one integrated platform.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" >
              Streamlined Workflow
            </TableCell>
            <TableCell>
              Export detailed summaries with a click, enhancing collaboration and efficiency in your projects.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

export default ProductDescription;
