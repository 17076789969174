import NavigationBar from "./NavigationBar";
import { Outlet } from 'react-router-dom';
import {  Stack } from "@mui/material";

// Updated LayoutWrapperProps without children
type LayoutWrapperProps = {};

// LayoutWrapper component with type annotations
export const LayoutWrapper: React.FC<LayoutWrapperProps> = () => (
  <PageLayout>
    <Outlet /> {/* Use Outlet for nested routes */}
  </PageLayout>
);

export const PageLayout = (props: any) => {
  return (
    <Stack direction='column' alignItems='center' justifyContent='center'>
      <NavigationBar  />
      {props.children}
    </Stack>
  );
}