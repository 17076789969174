import Button from '@mui/material/Button';
import { useMsal } from '@azure/msal-react';
import { signupRequest } from '../msalConfig';

function SignupButton() {

    const { instance } = useMsal();

   
    const handleLoginRedirect = () => {
        instance.loginRedirect(signupRequest).catch((error) => console.log(error));
    };

  return (
    <Button
            onClick={handleLoginRedirect}
          variant="contained"
          style={{
            background: 'linear-gradient(45deg, #a93d6f 30%, #f22e4e 90%)',
            borderRadius: 40,
            color: 'white',
            padding: '12px 60px', // Adjusted padding to control the size of the button itself
            fontSize: '1.5rem',
            textTransform: 'none',
            margin: '30px', // Adds space around the button
            
          }}
        >
          Sign Up
        </Button>
  );
}

export default SignupButton;
