import React, { createContext, useContext, useState, useEffect, ReactNode, useMemo } from 'react';
import { UserService } from './UserService';  // Import UserService
import { loginRequest, msalConfig } from './msalConfig';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';

interface FeatureFlagContextType {
  flags: { [key: string]: boolean };
  setFlags: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
}

// Create the Feature Flag Context
const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(undefined);

const serviceApi = new UserService(); // Create an instance of UserService

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
    const [flags, setFlags] = useState<{ [key: string]: boolean }>({});
    let defaultFeatures: string[] = useMemo(() => ['transcription'], []);
  
    useEffect(() => {
      async function getAccessToken() {
        if (serviceApi['axiosInstance'].defaults.headers.common['Authorization']) {
          return true;
        }
  
        const msalInstance = new PublicClientApplication(msalConfig);
        await msalInstance.initialize();
        const account = msalInstance.getAllAccounts()[0];
        if (!account) {
          return false;
        }
  
        const tokenRequest = {
          account,
          scopes: loginRequest.scopes
        };
  
        try {
          const response = await msalInstance.acquireTokenSilent(tokenRequest);
          let accessToken = response.accessToken;
          serviceApi['axiosInstance'].defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
          return true;
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            if (msalInstance) {
              msalInstance.acquireTokenRedirect(tokenRequest);
            }
          }
          return false;
        }
      }
  
      const fetchFeatureFlags = async () => {
        const isLoggedIn = await getAccessToken();
        const cachedFeatures = localStorage.getItem('features');
        const cachedTime = localStorage.getItem('featuresTime');
        const currentTime = new Date().getTime();
        const expiryTime = 10 * 60 * 1000; // 10 minutes in milliseconds
  
        if (cachedFeatures && cachedTime && (currentTime - Number(cachedTime)) < expiryTime) {
          setFlags(JSON.parse(cachedFeatures));
        } else {
          if (isLoggedIn) {
            try {
              let response = await serviceApi.getUserFeatures();  // Fetch features from UserService
              const availableFeatures = response.features;
              const featureFlags = availableFeatures.reduce((acc: { [key: string]: boolean }, feature: string) => {
                acc[feature] = true;
                return acc;
              }, {});
              setFlags(featureFlags);
              localStorage.setItem('features', JSON.stringify(featureFlags));
              localStorage.setItem('featuresTime', String(currentTime));
            } catch (error) {
              const featureFlags = defaultFeatures.reduce((acc: { [key: string]: boolean }, feature: string) => {
                acc[feature] = true;
                return acc;
              }, {});
              setFlags(featureFlags);
              localStorage.setItem('features', JSON.stringify(featureFlags));
              localStorage.setItem('featuresTime', String(currentTime));
              console.error('Error fetching feature flags:', error);
            }
          } else {
            const featureFlags = defaultFeatures.reduce((acc: { [key: string]: boolean }, feature: string) => {
              acc[feature] = true;
              return acc;
            }, {});
            setFlags(featureFlags);
            localStorage.setItem('features', JSON.stringify(featureFlags));
            localStorage.setItem('featuresTime', String(currentTime));
          }
        }
      };
  
      fetchFeatureFlags();
    }, [defaultFeatures]);
  
    return (
      <FeatureFlagContext.Provider value={{ flags, setFlags }}>
        {children}
      </FeatureFlagContext.Provider>
    );
  };

export const useFeatureFlag = (flag: string): boolean => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error('useFeatureFlag must be used within a FeatureFlagProvider');
  }
  const { flags } = context;
  return flags[flag] || false;
};
