import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosInstance } from 'axios';
import { loginRequest, msalConfig } from './msalConfig';



export interface FeaturesInfo {
    features: string[];
}

export interface GroupInfo {
    groupId: string;
    groupName: string;
    subGroups: GroupInfo[];
}

export interface SessionGroupInfo {
    sessionGroups: GroupInfo[];
}

export class UserService {
    private axiosInstance: AxiosInstance;
    private msalInstance: PublicClientApplication;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        });
        this.msalInstance = new PublicClientApplication(msalConfig);
    }

    private async getAccessToken() {
        if (this.axiosInstance.defaults.headers.common['Authorization']) {
            return;
        }

        await this.msalInstance.initialize();
        const account = this.msalInstance.getAllAccounts()[0];
        const tokenRequest = {
            account,
            scopes: loginRequest.scopes // Replace with your API scopes
        };

        try {
            const response = await this.msalInstance.acquireTokenSilent(tokenRequest);
            let accessToken = response.accessToken;
            this.axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            return;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                if (this.msalInstance) {
                    this.msalInstance.acquireTokenRedirect(tokenRequest);
                }
            }
            throw error;
        }
    }

    public async getUserFeatures(): Promise<FeaturesInfo> {
        await this.getAccessToken();
        const response = await this.axiosInstance.get<FeaturesInfo>('/api/User/features');
        return response.data;
    }

    public async getSessionGroups(): Promise<SessionGroupInfo> {
        await this.getAccessToken();
        const response = await this.axiosInstance.get<SessionGroupInfo>('/api/User/session-groups');
        return response.data;
    }

    public async addSessionGroup(groupName: string): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.post(`/api/User/session-groups/${groupName}`);
    }

    public async deleteSessionGroup(groupName: string): Promise<void> {
        await this.getAccessToken();
        await this.axiosInstance.delete(`/api/User/session-groups/${groupName}`);
    }
}
