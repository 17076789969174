import React from 'react';
import { Paper, Button } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { FileItem } from '../Types';
import { ExportFileItemsToCsv } from '../ExportToCsv';

function ExportButton({ files }: { files:  FileItem[] }) {
    return (
        <Paper
            elevation={6}
            sx={{
                width: 200,
                height: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px dashed grey'
            }}
        >
            <Button
                variant="contained"
                disabled={files.length === 0}
                onClick={() => ExportFileItemsToCsv(files)}
                startIcon={<CloudUploadIcon />}
            >
                Export
            </Button>
        </Paper>
    );
}

export default ExportButton;
