import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Home from "./Pages/Home";
import { LayoutWrapper } from './Components/PageLayout';
import { useMsal } from '@azure/msal-react';
import { LandingPage } from './Pages/Landing';
import Logs from './Pages/Logs';
import Log from './Pages/Log';
import Usage from './Pages/Usage';
import ProjectList from './Pages/ProjectAgliment';

type AppProps = {};

const App: React.FC<AppProps> = () => {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<LayoutWrapper />}>
            {/* Check authentication status for root path */}
            <Route path="/" element={<AuthCheck redirectTo="/home" />}>
              <Route index element={<LandingPage />} />
            </Route>

            {/* Protected Home route */}
            <Route element={<ProtectedRoute />}>
              <Route path="/home" element={<Home />} />
              <Route path="/logs" element={<Logs />} />
              <Route path="/log/:sessionId" element={<Log />} />
              <Route path="/usage" element={<Usage />} />
              <Route path="/projects" element={<ProjectList />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
};

// AuthCheck component to redirect authenticated users from LandingPage to Home
const AuthCheck = ({ redirectTo }: { redirectTo: string }) => {
  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  // If authenticated, redirect to the given path, otherwise render the Outlet (children routes)
  return isAuthenticated ? <Navigate to={redirectTo} /> : <Outlet />;
};

// ProtectedRoute component to check authentication status
const ProtectedRoute = () => {
  const { accounts } = useMsal();
  const isAuthenticated = accounts.length > 0;

  // If authenticated, render the Outlet (children routes) within FeatureFlagProvider
  return isAuthenticated ? (
   
      <Outlet />
  
  ) : (
    <Navigate to="/" />
  );
};
export default App;
