import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';
import { UpdateMemberRequest } from '../../ProjectAlignmentService';

interface MemberDialogProps {
  isOpen: boolean;
  isAddingMember: boolean;
  editMember: UpdateMemberRequest;
  onClose: () => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSaveMemberChanges: () => void;
  onDeleteMember?: () => void;
}

const MemberDialog: React.FC<MemberDialogProps> = ({
  isOpen,
  isAddingMember,
  editMember,
  onClose,
  onInputChange,
  onSaveMemberChanges,
  onDeleteMember,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{isAddingMember ? 'Add New Member' : 'Edit Member'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {isAddingMember ? 'Enter details for the new member.' : 'Edit the details of the member.'}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          value={editMember.name}
          onChange={onInputChange}
        />
        <TextField
          margin="dense"
          name="contactType"
          label="Contact Type"
          type="text"
          fullWidth
          variant="standard"
          value={editMember.contactType}
          onChange={onInputChange}
        />
        <TextField
          margin="dense"
          name="contactValue"
          label="Contact Value"
          type="text"
          fullWidth
          variant="standard"
          value={editMember.contactValue}
          onChange={onInputChange}
        />
        <TextField
          margin="dense"
          name="specialInstructions"
          label="Special Instructions"
          type="text"
          fullWidth
          variant="standard"
          value={editMember.specialInstructions}
          onChange={onInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={onSaveMemberChanges} color="primary">
          Save
        </Button>
        {!isAddingMember && onDeleteMember && (
          <Button onClick={onDeleteMember} color="secondary">
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MemberDialog;
