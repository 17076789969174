import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ProjectInfo } from '../../ProjectAlignmentService';


interface ProjectTableProps {
  projects: ProjectInfo[];
  onRowClick: (projectId: string | null) => void;
}

const ProjectTable: React.FC<ProjectTableProps> = ({ projects, onRowClick }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="project table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Project ID</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Created At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project) => (
            <TableRow key={project.projectId} onClick={() => onRowClick(project.projectId)} hover>
              <TableCell align="center">{project.projectId}</TableCell>
              <TableCell align="center">{project.name}</TableCell>
              <TableCell align="center">{project.description}</TableCell>
              <TableCell align="center">{project.createAtUtc.toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectTable;
