import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';

function DragAndDropZone({ handleOnDrop, handleDragOver, handleChooseFiles }: 
        { handleOnDrop: (event: React.DragEvent<HTMLDivElement>) => Promise<void>, 
        handleDragOver: (event: React.DragEvent<HTMLDivElement>) => Promise<void>, 
        handleChooseFiles: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> }) {
    return (
        <Paper
            elevation={6}
            className='drop_zone'
            onDrop={handleOnDrop}
            onDragOver={handleDragOver}
            sx={{
                width: 200,
                height: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px dashed grey'
            }}
        >
            <Stack direction="column" spacing={5} sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography>Drag and drop files here</Typography>
                <Box style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <input
                        style={{ display: 'none' }}
                        accept="audio/wav"
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={(event) => { handleChooseFiles(event); }}
                    />
                    <label htmlFor="raised-button-file" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="button" display="block">Choose Files</Typography>
                    </label>
                </Box>
            </Stack>
        </Paper>
    );
}

export default DragAndDropZone;
