import React, { useState, useEffect, useRef } from 'react';
import { Box, Paper, TextField, Button, Typography, Grid, Chip, List, ListItem, ListItemText } from '@mui/material';
import { UpdateProjectRequest, ProjectDto } from '../../ProjectAlignmentService';

interface ProjectDetailsProps {
  editProject: UpdateProjectRequest;
  isEditing: boolean;
  isAdding: boolean;
  selectedProject: ProjectDto | null;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSaveChanges: () => void;
  onEditClick: () => void;
  onDeleteProject: () => void;
  onCancelClick: () => void;
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  editProject,
  isEditing,
  isAdding,
  selectedProject,
  onInputChange,
  onSaveChanges,
  onEditClick,
  onDeleteProject,
  onCancelClick,
}) => {
  const [nameError, setNameError] = useState<string | null>(null);
  const messageTemplateRef = useRef<HTMLTextAreaElement>(null);
  const systemNoteRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (messageTemplateRef.current) {
      messageTemplateRef.current.style.height = 'auto';
      messageTemplateRef.current.style.height = `${messageTemplateRef.current.scrollHeight}px`;
    }
    if (systemNoteRef.current) {
      systemNoteRef.current.style.height = 'auto';
      systemNoteRef.current.style.height = `${systemNoteRef.current.scrollHeight}px`;
    }
  }, [editProject.messageTemplate, selectedProject?.systemNote]);

  const handleSaveChanges = () => {
    if (!editProject.name || !editProject.name.trim()) {
        setNameError('Name is required.');
        return;
    }
    setNameError(null);
    onSaveChanges();
  };

  const getProjectStateIndicator = (state: string | null) => {
    switch (state) {
      case 'update-require':
        return <Chip label="Update Required" color="error" />;
      case 'update-members':
          return <Chip label="Add Members" color="error" />;
      case 'review-in-progress':
        return <Chip label="Review In Progress" color="warning" />;
      case 'ready':
        return <Chip label="Ready" color="success" />;
      default:
        return <Chip label="Unknown State" />;
    }
  };

  return (
    <Box sx={{ mt: 4, width: '100%' }}>
      <Typography variant='h5' gutterBottom>
        {isAdding ? 'Add New Project' : 'Project Details'}
      </Typography>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Grid container spacing={2}>
          {selectedProject && !isAdding && (
            <Grid item xs={6}>
              <TextField
                label="Project ID"
                value={selectedProject.projectId}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <TextField
              label="Name"
              name="name"
              value={editProject.name}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: !isEditing }}
              error={!!nameError}
              helperText={nameError}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={editProject.description}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: !isEditing }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Message Template Instructions:</Typography>
            <Typography variant="body2" gutterBottom>
              To create a good message template, you can use the following parameters:
            </Typography>
            <List dense>
              <ListItem>
                <ListItemText primary="{name} - Inserts the member's name into the message" />
              </ListItem>
              {/* Add more parameters here as needed */}
            </List>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message Template"
              name="messageTemplate"
              value={editProject.messageTemplate}
              onChange={onInputChange}
              fullWidth
              margin="normal"
              InputProps={{ readOnly: !isEditing }}
              multiline
              inputRef={messageTemplateRef}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = 'auto';
                target.style.height = `${target.scrollHeight}px`;
              }}
            />
          </Grid>
          {selectedProject && (
            <Grid item xs={12}>
              <TextField
                label="System Note"
                name="systemNote"
                value={selectedProject.systemNote}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
                multiline
                inputRef={systemNoteRef}
                onInput={(e) => {
                  const target = e.target as HTMLTextAreaElement;
                  target.style.height = 'auto';
                  target.style.height = `${target.scrollHeight}px`;
                }}
              />
            </Grid>
          )}
          {selectedProject && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">Project State:</Typography>
              {getProjectStateIndicator(selectedProject.projectState)}
            </Grid>
          )}
        </Grid>
        {!isEditing ? (
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button variant="contained" color="primary" onClick={onEditClick}>
              Edit
            </Button>
            <Button variant="contained" color="secondary" onClick={onDeleteProject}>
              Delete
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
            <Button variant="outlined" color="secondary" onClick={onCancelClick}>
              Cancel
            </Button>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default ProjectDetails;
