import axios from 'axios';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './msalConfig';

const serviceApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

async function getAccessToken() {
    if (serviceApi.defaults.headers.common['Authorization']) {
        return;
    }

    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    const account = msalInstance.getAllAccounts()[0];
    const tokenRequest = {
        account,
        scopes: loginRequest.scopes // Replace with your API scopes
    }
    try {
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        let accessToken = response.accessToken;
        serviceApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        return;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            if (msalInstance) {
                msalInstance.acquireTokenRedirect(tokenRequest);
            }
        }
    }
    try {
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        return response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            if (msalInstance) {
                msalInstance.acquireTokenRedirect(tokenRequest);
            }
        }
        throw error;
    }
}

export async function createSession(): Promise<string> {
    await getAccessToken();
    const sessionResponse = await serviceApi.post('/api/Sessions');
    let sessionId = sessionResponse.data.sessionId as string;
    return sessionId;
}

export async function updateSession(sessionId: string, file: File, result: any) {
    try {
        await getAccessToken();
        const updateSessionRequest = {
            SessionId: sessionId,
            FileName: file.name,
            Transcription: result.text,
            Status: 'ok',
        };
        let updateResponse = await serviceApi.put('/api/Sessions', updateSessionRequest);
        console.log('Update Session: ', updateSessionRequest, updateResponse);
        return updateResponse;
    } catch (error) {
        console.error('Error updating session:', error);
        throw error;
    }
}

export interface SessionView {
    sessionId: string;
    groupName: string; 
    name: string; 
    itemCount: number;
    characterCount: number;
    createAtUtc: Date;
}

export async function getSessions(): Promise<SessionView[]> {
    await getAccessToken();
    const response = await serviceApi.get('/api/Sessions');
    return response.data.map((session: any) => ({
        sessionId: session.sessionId,
        groupName: session.groupName,
        name: session.name, 
        itemCount: session.itemCount,
        characterCount: session.characterCount,
        createAtUtc: new Date(session.createAtUtc),
    }));
}

export interface SessionItemView {
    id: string;
    fileName: string;
    transcription: string;
    status: string;
    createdAtUtc: Date;
}

export interface SessionDetailView {
    sessionId: string;
    groupName: string; // Added GroupName property
    name: string; // Added name property
    itemCount: number;
    characterCount: number;
    createAtUtc: Date;
    items: SessionItemView[];
}

export async function getSessionDetails(sessionId: string): Promise<SessionDetailView> {
    await getAccessToken();
    const response = await serviceApi.get(`/api/Sessions/${sessionId}`);
    const session = response.data;
    return {
        sessionId: session.sessionId,
        groupName: session.groupName, 
        name: session.name, 
        itemCount: session.itemCount,
        characterCount: session.characterCount,
        createAtUtc: new Date(session.createAtUtc),
        items: session.items.map((item: any) => ({
            id: item.id,
            fileName: item.fileName,
            transcription: item.transcription,
            status: item.status,
            createdAtUtc: new Date(item.createdAtUtc),
        })),
    };
}

export interface UsageEOMView {
    id: string;
    eom: Date;
    characterCount: string;
}

export async function getUsageByEOM(): Promise<UsageEOMView[]> {
    await getAccessToken();
    const response = await serviceApi.get('/api/Usage/by_eom');
    return response.data.map((usage: UsageEOMView) => ({
        id: usage.id,
        eom: usage.eom,
        characterCount: usage.characterCount,
    }));
}

export async function updateSessionName(sessionId: string, name: string): Promise<void> {
    try {
        await getAccessToken();
        const updateNameRequest = {
            SessionId: sessionId,
            Name: name,
        };
        await serviceApi.put('/api/Sessions/name', updateNameRequest);
    } catch (error) {
        console.error('Error updating session name:', error);
        throw error;
    }
}

export async function updateSessionGroupName(sessionId: string, groupName: string): Promise<void> {
    try {
        await getAccessToken();
        const updateGroupNameRequest = {
            SessionId: sessionId,
            GroupName: groupName,
        };
        await serviceApi.put('/api/Sessions/group-name', updateGroupNameRequest);
    } catch (error) {
        console.error('Error updating session group name:', error);
        throw error;
    }
}
