import {  Container, Box,  Grid } from '@mui/material';
import ProductDescription from '../Components/ProductDescription';
import SignupButton from '../Components/SignupButton';
export const LandingPage = (props: any) => {
  return (
    <Container maxWidth="lg" style={{ height: '100vh', display: 'flex', flexDirection: 'column', padding: 0, margin: '0 auto' }}>
      {/* Parent Box wrapping both the hero image and the product description */}
      <Box style={{ position: 'relative', width: '100%', overflow: 'hidden' }}>
        {/* Hero image Box */}
        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={process.env.PUBLIC_URL + '/landing_hero.png'} alt="Hero" style={{ width: '100%', objectFit: 'cover', maxHeight: '80vh' }} />
        </Box>
        {/* Product description Box */}
        <Grid style={{
          position: 'absolute', // Changed to absolute positioning
          top: '40%', // Position at 50% from the top of the parent
          left: '50%', // Position at 50% from the left of the parent
          transform: 'translate(-50%, -50%)', // Adjust to center precisely
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.65)', // Optional: added a semi-transparent background for better readability
          padding: '20px',
          borderRadius: '10px',
          color: 'white', // Set the text color to white for all child elements
        }}>
          <Grid item>
            <ProductDescription />
          </Grid>
          <Grid item>
            <SignupButton />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
